.k-404
	place-self center
	width 50%
	max-width 500px
	padding 10px
	.k-logo
		display block
		text-align center
		max-width 75%
		height auto
		margin 10px auto 20px auto
		height auto
		cursor pointer
		padding 20px 50px
		
	.k-button
		font-size $FontSizeL
		font-family $KartanalysFontNormal
		font-weight 800
		margin $space-s auto
		
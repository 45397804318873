.k-topbar 
	background $white
	display grid
	grid-template-areas "logo nav opts"
	grid-template-columns 20% 60% 20%
	align-content stretch
	height 100%
	align-items center
	a, a .fa
		color $defaultColor

	.k-topbar-logo
		grid-area logo
		place-self center start
		align-self center
		margin-left $space-m
		.k-logo
			max-width 120px
			//margin-left $space-m
			cursor pointer
	.k-topbar-nav
		grid-area nav
		//align-self end
		//place-self end
		//justify-self start
		justify-self center
		align-self end
			
		.k-tabs 
			display block
			border 0
			color $defaultColor
			.k-tab
				text-decoration none
				cursor pointer
				background $primaryBackground
				padding $space-m $space-l
				color $colorNeutral300
				//font-size $FontSizeL
				font-family $KartanalysFontNormal
				font-weight 800
				line-height $FontSizeL
				border 1px solid $primaryBackground
				border-bottom 0
				border-top-left-radius $space-xs
				border-top-right-radius $space-xs
				i.fa 
					//font-size $FontSizeL
					margin-right $space-s
					color $colorNeutral300
				&.k-tab-active
					border-color $white
					background $white
					color $defaultColor
					i.fa 
						color $defaultColor
			.k-tab:hover
				color $colorNeutral300
				&.k-tab-active
					background $white
					color $defaultColor
					i.fa 
						color $defaultColor
	.k-topbar-options
		grid-area opts
		place-self center
		margin-right $space-l
		justify-self right 
			
		
			
@require './ManageUser.styl'
@require './UserList.styl'



.k-users
	
	.k-view-top
		border-bottom 1px solid $colorNeutral200
		margin $space-l 0 $space-m 0
		padding-bottom $space-m
		.k-header
			grid-area topleft
			border 0
			margin 0
			padding 0
			> h1,h2,h3
				display flex
				align-items center
				justify-content flex-start
				margin 0
				padding 0
				> .k-button
					margin-right $space-m
					padding $space-s
			.k-icon-button
				> i
					color $colorNeutral300
		.k-option-panel
			grid-area topright
			text-align right
		.k-error-panel
			grid-area topcenter
			.k-alert
				margin 0
			.k-info, .k-error, .k-success, .k-warning
				margin 0
			p
				font-size $FontSizeL    	
.k
	display grid
	height 100vh
	/*
	// Smartphones (portrait and landscape) ----------- 
	@media only screen and (min-device-width : 320px) and (max-device-width : 480px) 
		// Styles 
	

	// Smartphones (landscape) ----------- 
	@media only screen and (min-width : 321px)
		// Styles 
	

	// Smartphones (portrait) ----------- 
	@media only screen and (max-width : 320px)
		// Styles 
	

	// iPads (portrait and landscape) ----------- 
	@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) 
		// Styles 
	

	// iPads (landscape) ----------- 
	@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape)
		// Styles 
	

	// iPads (portrait) ----------- 
	@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) 
		// Styles 
	

	// Desktops and laptops ----------- 
	@media only screen and (min-width : 1224px) 
		// Styles 
		font-size 1.1em

	// Larger screeens and laptops ----------- 
	@media only screen and (min-width : 1600px) 
		// Styles 
		font-size 1.1em
	*/
	// Large screens ----------- 
	@media only screen and (min-width : 1823px) 
		// Styles 
		font-size 1em
	@media only screen and (min-width : 1824px) 
		// Styles 
		font-size 1.1em
		@media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (max-device-pixel-ratio : 3) 
			font-size 1em
			.k-chart
				font-size 1.3em
	@media only screen and (min-width : 2300px) 
		// Styles 
		font-size 1.2em
	

	// iPhone 4 ----------- 
	@media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) 
		// Styles 

.k-app 
	display grid
	grid-template-areas "head head" "main main"
	grid-template-rows 4em auto
	.k-main
		display grid
		grid-area main
		grid-gap 10px
		background-color $white
		//padding $space-l 0
	.k-topbar
		grid-area head
		background-color $primaryBackground


.k-user-manage
	display grid
	width 100%
	height 100vh
	grid-template-columns 20% 60% 20%
	grid-template-rows auto 1fr 1fr
	grid-template-areas ". topbar ." ". main ."
	.k-user-manage-content
		grid-area main
	.k-user-manage-top 
		grid-area topbar
		place-self center start		
		margin $space-s 0 
		align-items center
		justify-content flex-start
		
		display grid
		width 100%
		grid-template-columns 1fr 1fr
		grid-template-areas "top-left top-right"
		border-bottom 1px solid $colorNeutral200

		.k-user-manage-user-title
			grid-area top-left
			display flex
			flex-direction row
			flex-wrap nowrap
			align-items stretch
			width 100%
			> *
				order 1
				flex-grow 0
				align-self center

			.k-map-topbar-name
				font-family $KartanalysFontNormal
				font-weight 800
				font-size $FontSizeXL
				padding 0
				flex-grow 1
			> .k-button
				margin-right $space-m
				padding $space-s
			.k-icon-button
				> i
					color $colorNeutral300
			.k-map-topbar-back-button
				margin-right $space-m
				background $white
				padding $space-s $space-m
				border-radius $space-s
				cursor pointer
			.k-icon-button
					margin-left $space-m

		.k-option-panel
			grid-area top-right
			.k-button-selected
				background-color $colorNeutral200

		.k-option-panel
			text-align right

	.k-table
		thead
			tr
				th:not(:first-of-type)
					text-align center
					img
						margin 0 auto

		tbody
			tr
				td:not(:first-of-type)
					text-align center
					i.fa 
						font-size $FontSizeXL


.k-routine-usage-list
	display grid
	width 100%
	height 100vh
	grid-template-columns 10% 80% 10%
	grid-template-rows auto 1fr 1px
	grid-template-areas ". topbar ." ". main ." ". footer ."
	.k-routine-usage-list-top
		grid-area topbar
		border-bottom 1px solid $colorNeutral200
		margin $space-m 0
		padding-bottom $space-m
		align-items center
		align-self end
		justify-content flex-start
		display grid
		width 100%
		grid-template-columns 50% 50%
		grid-template-areas "top-left top-right"
		
		.k-header
			grid-area top-left
			border 0
			margin 0
			padding 0
			> h1,h2,h3
				margin 0
				padding 0
				> .k-button
					margin-right $space-m
					padding $space-s
			.k-icon-button
				> i
					color $colorNeutral300

		.k-option-panel
			grid-area top-right
			text-align right


	.k-routine-usage-list-table
		grid-area main
		.k-table
			thead tr th:nth-child(1), thead tr td:nth-child(1)
					width 3em

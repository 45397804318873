.k-account-manage
	display grid
	width 100%
	height 100vh
	grid-template-columns 20% 60% 20%
	grid-template-rows auto 1fr 1fr
	grid-template-areas ". topbar ." ". main ."
	.k-account-manage-content
		grid-area main
	.k-account-manage-top 
		grid-area topbar
		place-self center start		
		margin $space-s 0 
		align-items center
		justify-content flex-start
		
		display grid
		width 100%
		grid-template-columns 1fr 1fr
		grid-template-areas "top-left top-right"
		border-bottom 1px solid $colorNeutral200

		.k-account-manage-account-title
			grid-area top-left
			display flex
			flex-direction row
			flex-wrap nowrap
			align-items stretch
			width 100%
			> *
				order 1
				flex-grow 0
				align-self center

			.k-map-topbar-name
				font-family $KartanalysFontNormal
				font-weight 800
				font-size $FontSizeXL
				padding 0
				flex-grow 1
			> .k-button
				margin-right $space-m
				padding $space-s
			.k-icon-button
				> i
					color $colorNeutral300
			.k-map-topbar-back-button
				margin-right $space-m
				background $white
				padding $space-s $space-m
				border-radius $space-s
				cursor pointer
			.k-icon-button
					margin-left $space-m

		.k-option-panel
			grid-area top-right
			.k-button-selected
				background-color $colorNeutral200

		.k-option-panel
			text-align right


	.k-country-selector
		margin-bottom $space-m
		display flex
		flex-direction row
		flex-wrap nowrap
		align-items stretch
		width 100%
		> label
			order 1
			flex-grow 1
			align-self center
			display grid
			align-items center
			grid-template-columns 1.5rem 2.5rem auto
			grid-template-rows auto
			grid-template-areas "checkbox flag title"
			input
				grid-area checkbox
			.k-flag
				margin 0 $space-xs
				grid-area flag
			.k-country-title
				grid-area title
				font-family $KartanalysFontNormal
